<template>
    <div>
        <button v-on:click="sendRequestButton($event)" value="overview" type="button">نمای کلی</button>
        <button v-on:click="sendRequestButton($event)" value="performance" type="button">عملکرد</button>
        <button v-on:click="sendRequestButton($event)" value="ath" type="button">ترین ها</button>
        <div class="filter-wrapper">
            <div class="labeled" data-target="crypto-tour-step-5">
                <div>
                    <span>
                        <i class="fa fa-pie-chart" aria-hidden="true"></i>
                        نوع بازار
                    </span>
                </div>
                <div class="labeled-data">
                    <select class="filter-market" v-model="cryptoCategory" @change="filterMarketChanged()">
                        <option 
                        v-for="(item, index) in cryptoFilterMarket"
                        :key="index"
                        :value="index">
                        {{ item.value }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="filter-wrapper hide-in-m">
            <div class="labeled" data-target="crypto-tour-step-4">
                <div>
                    <span><i class="fa fa-search" aria-hidden="true"></i>جستجو</span>
                </div>
                <div class="labeled-data search-box">
                    <div class="date-icon list-search-btn"></div>
                    <input type="text" class="search exchangeFilter" placeholder="نام یا نماد را وارد نمایید" value="" name="search">
                </div>
            </div>
        </div>
        <div class="table-fixed">
            <table id="stocks-table" class="widgets-dataTable table data-table crypto-data-table-table">
            </table>
        </div>
    </div>
</template>

<script>
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import Centrifuge from "centrifuge";
import $ from 'jquery';

import cryptoFilterMarket from "@/assets/static_crypto_filter_market";
export default {
    name: 'CryptoView',
    component: {
    },
    data() {
        return {
            cryptoFilterMarket,
            cryptoCategory: 'empty',
            cryptoType: 'overview',
            limit: 10,
            columns: {
                // ارزها / اجمالی
                overview: [
                    {
                        title: '<span class="tour-selection" data-target="crypto-tour-step-6">ارزهای دیجیتال</span>',
                        data: 'title',
                        render: function (data, type, row) {
                            var title = row.title_fa ? row.title_fa : row.title;
                            var title_en = row.title_en;
                            return "<div class='crypto-td-has-sub'>"
                                    + "<span class='crypto-icon'><img src='https://static.tgju.org/images/crypto/icons/"+ (row.combine_symbol).toLowerCase() +".png' alt='"+ row.title +"' width='20' height='20'></span>"
                                    + "<span class='crypto-title'>"
                                    + "	<div class='crypto-title-text'><a href=\"market/profile/"+ row.item_id +"/summary\">"+row.title_fa+"</a></div>"
                                    + "	<div class='crypto-title-sub'><a href=\"market/profile/"+ row.item_id +"/summary\">"+row.title_en+"</a></div>"
                                    + "</span>"
                                    + "</div>";
                        }
                    },
                    {
                        title: '<span class="tour-selection" data-target="crypto-tour-step-7">نماد</span>',
                        data: 'symbol',
                        render: function (data, type, row) {
                            return "<span class='badge-tag width-75'>"
                                + data
                                + "</span>";
                        }
                    },
                    {
                        title: '<span class="tour-selection" data-target="crypto-tour-step-8">قیمت (دلار)</span>',
                        data: 'p',
                        render: function (data, type, row) {
                            var result = '<span class="badge-tag width-100" data-market-name="p">'+ data +'</span>';
                            if (row.dt === 'high') {
                                result = '<span class="badge-tag badge-tag-green width-100" data-market-name="p">'+ data +'</span>';
                            } else if (row.dt === 'low') {
                                result = '<span class="badge-tag badge-tag-red width-100" data-market-name="p">'+ data +'</span>';
                            }

                            return result;
                        }
                    },
                    {
                        title: '<span class="tour-selection" data-target="crypto-tour-step-9">قیمت (ریال)</span>',
                        data: 'p_irr'
                    },
                    {
                        title: '<span class="tour-selection" data-target="crypto-tour-step-10">تغییر</span>',
                        data: 'd',
                        render: function (data, type, row) {
                            return "<span class='change-cell "+ row.dt +"' data-market-name='dv'>"
                                + data
                                + "</span>";
                        }
                    },
                    {
                        title: '<span class="tour-selection" data-target="crypto-tour-step-11">درصد تغییر</span>',
                        data: 'dp',
                        render: function (data, type, row) {
                            return "<span class='change-cell "+ row.dt +"' data-market-name='dp'>"
                                + data
                                + "</span>";
                        }
                    },
                    {
                        title: '<span class="tour-selection" data-target="crypto-tour-step-12">ارزش بازار</span>',
                        data: 'cap'
                    },
                    {
                        title: '<span class="tour-selection" data-target="crypto-tour-step-13">حجم بازار</span>',
                        data: 'volume'
                    },
                    {
                        title: '<span class="tour-selection" data-target="crypto-tour-step-14">زمان</span>',
                        data: 'datetime',
                        render: function (data, type, row) {
                            return "<span class='currency-date' data-market-name='datetime'>"
                                + data
                                + "</span>";
                        }
                    },
                    {
                        title: '<span class="tour-selection" data-target="crypto-tour-step-15">پروفایل</span>',
                        render: function (data, type, row) {
                            return "<span title='نمودار' class='chart-icon'></span>";
                        }
                    },
                ],

                // ارزها / عملکرد
                performance: [
                    {
                        title: '<span class="tour-selection" data-target="crypto-tour-step-6">ارزهای دیجیتال</span>',
                        data: 'title',
                        render: function (data, type, row) {
                            var title = row.title_fa ? row.title_fa : row.title;
                            var title_en = row.title_en;
                            return "<div class='crypto-td-has-sub'>"
                                    + "<span class='crypto-icon'><img src='https://static.tgju.org/images/crypto/icons/"+ (row.combine_symbol).toLowerCase() +".png' alt='"+ row.title +"' width='20' height='20'></span>"
                                    + "<span class='crypto-title'>"
                                    + "	<div class='crypto-title-text'><a href=\"market/profile/"+ row.item_id +"/summary\">"+row.title_fa+"</a></div>"
                                    + "	<div class='crypto-title-sub'><a href=\"market/profile/"+ row.item_id +"/summary\">"+row.title_en+"</a></div>"
                                    + "</span>"
                                    + "</div>";
                        }
                    },
                    {
                        title: '<span class="tour-selection" data-target="crypto-tour-step-7">نماد</span>',
                        data: 'symbol'
                    },
                    {
                        title: '<span class="tour-selection" data-target="crypto-tour-step-8">قیمت (دلار)</span>',
                        data: 'p',
                        render: function (data, type, row) {
                            var result = '<span class="badge-tag width-100" data-market-name="p">'+ data +'</span>';
                            if (row.dt === 'high') {
                                result = '<span class="badge-tag badge-tag-green width-100" data-market-name="p">'+ data +'</span>';
                            } else if (row.dt === 'low') {
                                result = '<span class="badge-tag badge-tag-red width-100" data-market-name="p">'+ data +'</span>';
                            }

                            return result;
                        }
                    },
                    {
                        title: '<span class="tour-selection" data-target="crypto-tour-step-9">24 ساعت</span>',
                        render: (data, type, row) => {
                            if (typeof(row.cr.category) === 'undefined') {
                                return '-';
                            }

                            return this.performance_cell(row.cr['price-usd'], row.cr['highest-24h-usd']);
                        }
                    },
                    {
                        title: '<span class="tour-selection" data-target="crypto-tour-step-10">یک هفته</span>',
                        render: (data, type, row) => {
                            if (typeof(row.cr.category) === 'undefined') {
                                return '-';
                            }

                            return this.performance_cell(row.cr['price-usd'], row.cr['highest-7d-usd']);
                        }
                    },
                    {
                        title: '<span class="tour-selection" data-target="crypto-tour-step-11">یک ماه</span>',
                        render: (data, type, row) => {
                            if (typeof(row.cr.category) === 'undefined') {
                                return '-';
                            }

                            return this.performance_cell(row.cr['price-usd'], row.cr['highest-30d-usd']);
                        }
                    },
                    {
                        title: '<span class="tour-selection" data-target="crypto-tour-step-12">سه ماه</span>',
                        render: (data, type, row) => {
                            if (typeof(row.cr.category) === 'undefined') {
                                return '-';
                            }

                            return this.performance_cell(row.cr['price-usd'], row.cr['highest-3m-usd']);
                        }
                    },
                    {
                        title: '<span class="tour-selection" data-target="crypto-tour-step-13">شش ماه</span>',
                        render: (data, type, row) => {
                            if (typeof(row.cr.category) === 'undefined') {
                                return '-';
                            }

                            return this.performance_cell(row.cr['price-usd'], row.cr['highest-6m-usd']);
                        }
                    },
                    {
                        title: '<span class="tour-selection" data-target="crypto-tour-step-14">از ابتدای سال</span>',
                        render: (data, type, row) => {
                            if (typeof(row.cr.category) === 'undefined') {
                                return '-';
                            }

                            return this.performance_cell(row.cr['price-usd'], row.cr['highest-ytd-usd']);
                        }
                    },
                    {
                        title: '<span class="tour-selection" data-target="crypto-tour-step-15">یک سال</span>',
                        render: (data, type, row) => {
                            if (typeof(row.cr.category) === 'undefined') {
                                return '-';
                            }

                            return this.performance_cell(row.cr['price-usd'], row.cr['highest-1y-usd']);
                        }
                    },
                    {
                        title: '<span class="tour-selection" data-target="crypto-tour-step-16">نوسان</span>',
                        render: function (data, type, row) {
                            if (typeof(row.cr.category) === 'undefined') {
                                return '-';
                            }

                            return row.cr['volatility-usd'] +'%';
                        }
                    },
                    {
                        title: '<span class="tour-selection" data-target="crypto-tour-step-17">پروفایل</span>',
                        render: function (data, type, row) {
                            return "<span title='نمودار' class='chart-icon'></span>";
                        }
                    },
                ],

                // ارزها / ترین‌ها
                ath: [
                    {
                        title: '<span class="tour-selection" data-target="crypto-tour-step-6">ارزهای دیجیتال</span>',
                        data: 'title',
                        render: function (data, type, row) {
                            var title = row.title_fa ? row.title_fa : row.title;
                            var title_en = row.title_en;
                            return "<div class='crypto-td-has-sub'>"
                                    + "<span class='crypto-icon'><img src='https://static.tgju.org/images/crypto/icons/"+ (row.combine_symbol).toLowerCase() +".png' alt='"+ row.title +"' width='20' height='20'></span>"
                                    + "<span class='crypto-title'>"
                                    + "	<div class='crypto-title-text'><a href=\"market/profile/"+ row.item_id +"/summary\">"+row.title_fa+"</a></div>"
                                    + "	<div class='crypto-title-sub'><a href=\"market/profile/"+ row.item_id +"/summary\">"+row.title_en+"</a></div>"
                                    + "</span>"
                                    + "</div>";
                        }
                    },
                    {
                        title: '<span class="tour-selection" data-target="crypto-tour-step-7">نماد</span>',
                        data: 'symbol'
                    },
                    {
                        title:'<span class="tour-selection" data-target="crypto-tour-step-8">قیمت فعلی / دلار</span>',
                        data: 'p',
                        render: function (data, type, row) {
                            var result = '<span class="badge-tag width-100" data-market-name="p">'+ data +'</span>';
                            if (row.dt === 'high') {
                                result = '<span class="badge-tag badge-tag-green width-100" data-market-name="p">'+ data +'</span>';
                            } else if (row.dt === 'low') {
                                result = '<span class="badge-tag badge-tag-red width-100" data-market-name="p">'+ data +'</span>';
                            }

                            return result;
                        }
                    },
                    {
                        title:'<span class="tour-selection" data-target="crypto-tour-step-9">بالاترین همه ادوار / دلار</span>',
                        render: function (data, type, row) {
                            if (typeof(row.cr.category) === 'undefined') {
                                return '-';
                            }
                            return parseFloat(row.cr['ath-price-usd']).toFixed(4);
                        }
                    },
                    {
                        title:'<span class="tour-selection" data-target="crypto-tour-step-10">% از بالاترین همه ادوار</span>',
                        render: (data, type, row) => {
                            if (typeof(row.cr.category) === 'undefined') {
                                return '-';
                            }

                            return this.performance_cell(row.cr['ath-price-usd'], row.cr['price-usd'], true);
                        }
                    },
                    {
                        title:'<span class="tour-selection" data-target="crypto-tour-step-11">% به بالاترین همه ادوار</span>',
                        render: (data, type, row) => {
                            if (typeof(row.cr.category) === 'undefined') {
                                return '-';
                            }

                            return this.performance_cell(row.cr['price-usd'], row.cr['ath-price-usd'], true);
                        }
                    },
                    {
                        // title: "پروفایل",
                        title:'<span class="tour-selection" data-target="crypto-tour-step-12">پروفایل</span>',
                        render: function (data, type, row) {
                            return "<span title='نمودار' class='chart-icon'></span>";
                        }
                    },
                ],

            },
        }
    },
    mounted() {
        this.cryptoTable();
        this.socketMarket();
    },
    methods: {
        // این متد ارتباط با سوکت را برقرار میکند و سپس داده های موجود در واچ لیست را آپدیت میکند
        socketMarket() {
            // TODO: نیاز به تغییر آی پی دارد
            var centrifuge = new Centrifuge("ws://localhost:8000/connection/websocket");
            centrifuge.setToken("eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2NTU5NzE3OTZ9.IkVEdKpmQvKyNgyOlmSmsjgzBKbtG4vyT2ZE31u4W68");
            centrifuge.subscribe("public", (ctx) => {
                var format_data = this.parse_market_data(ctx.data);
                this.market_process(format_data, false);
            });

            centrifuge.on('connect', function(ctx) {
                console.info('WebSocket connection established (latency: ' + ctx.latency + ')');
            });

            centrifuge.on('disconnect', function(ctx) {
                console.warn('WebSocket connection closed');
            });

            centrifuge.connect();
        },
        // وظیفه ساختن دیتاتیبل جدید
        cryptoTable() {
            var table_obj = $('.crypto-data-table-table');

            var all_tables = table_obj.DataTable({
                language: {
                    "sProcessing":   "درحال پردازش...",
                    "sLengthMenu":   "نمایش محتویات : _MENU_",
                    "sZeroRecords":  "موردی یافت نشد",
                    "sInfo":         "نمایش _START_ تا _END_ از مجموع _TOTAL_ مورد",
                    "sInfoEmpty":    "خالی",
                    "sInfoFiltered": "(فیلتر شده از مجموع _MAX_ مورد)",
                    "sInfoPostFix":  "",
                    "sSearch":       "فیلتر",
                    "sUrl":          "",
                    "oPaginate": {
                        "sFirst":    "ابتدا",
                        "sPrevious": "قبلی",
                        "sNext":     "بعدی",
                        "sLast":     "انتها"
                    }
                },
                aaSorting: [],
                paging: true,
                info: true,
                pageLength: this.limit,
                sDom: "<'row'<'col-md-9'l T C><'col-md-3'f>r>t<'row'<'col-md-12'p i>>R",
                direction: 'rtl',
                processing: true,
                serverSide: true,
                ajax: {
                    "url": "https://api.tgju.org/v1/market/dataservice/crypto-assets?category="+ ((this.cryptoCategory === 'empty') ? '' : this.cryptoCategory) +"&type=" + this.cryptoType + "&length=1",
                    "dataType": "json"
                },
                columns: this.columns[this.cryptoType],
                createdRow: function ( row, data, index ) {
                    $(row).attr("onclick", "window.location = 'market/profile/" + data.item_id + '/summary' +"'");
                    $(row).attr("data-market-coding", true);
                    $(row).attr("data-market-row", data.item_id);
                },
                columnDefs: this.cryptoType === 'overview' ? [
                                                                {
                                                                    'targets': 3,
                                                                    'createdCell':  function (td, cellData, rowData, row, col) {
                                                                        if (rowData.slug) {
                                                                            $(td).attr('data-market-p', rowData.slug + '-irr');
                                                                        }
                                                                    }
                                                                }
                                                            ] : [],
                responsive: false,
            });
            $('.search').keyup(function(){
                all_tables.search($(this).val()).draw() ;
            })
        },
        // انتخاب دسته بندی نوع بازار
        filterMarketChanged() {
            $('.crypto-data-table-table').DataTable().clear();
            $('.crypto-data-table-table').DataTable().destroy();
            this.cryptoTable();
        },
        // انتخاب دکمه (نمای کلی-عملکرد-ترین ها)
        sendRequestButton(event) {
            this.cryptoType = event.target.value;
            $('.crypto-data-table-table').DataTable().clear();
            $('.crypto-data-table-table').DataTable().destroy();
            $("thead").remove();
            this.cryptoTable();
        },
        performance_cell(firstValue, secondValue, reverseChange) {
            var result = (((firstValue - secondValue) / (firstValue)) * 100).toFixed(2);
            var dt = result < 0 ? 'low' : 'high';
            if (reverseChange) {
                dt = result < 0 ? 'high' : 'low';
            }
            
            return '<span class="change-cell '+dt+'" dir="ltr">'
                + result.replace('-', '') + '%'
                + '</span>';
        },
        parse_market_data(data) {
            var count = 0;
            var format_data = {
                current: {},
                tolerance_low: [],
                tolerance_high: [],
                last: []
            };
            for (var i in data) {
                var item = data[i].split("|");
                if (item[0] === 'crypto') {
                    var fitem = {
                        type: item[0],
                        item_id: item[1],
                        name: item[2],
                        p: item[6],
                        h: item[7],
                        l: item[8],
                        o: item[9],
                        d: item[10],
                        dp: item[11],
                        dt: item[12],
                        t: item[13],
                        t_en: item[13],
                        ts: item[14]
                    };
                    var k = item[1];
                    format_data.current[k] = fitem;
                    count++;
                }
            }
            if (!format_data.last.length) {
                delete format_data.last;
            }
            if (!format_data.tolerance_low.length) {
                delete format_data.tolerance_low;
            }
            if (!format_data.tolerance_high.length) {
                delete format_data.tolerance_high;
            }
            return format_data;
        },
        market_process(data, is_ajax) {
            if (data) {
                var this_data = data

                if (typeof window.source_price == "undefined")
                    window.source_price = new Object();
                if (typeof this_data.current.price_dollar_rl != "undefined") {
                    window.source_price['price_dollar_rl'] = this_data.current.price_dollar_rl.p.replace(/,/g, '');
                }
                if (typeof this_data.current.price_dollar_realtime != "undefined") {
                    window.source_price['price_dollar_realtime'] = this_data.current.price_dollar_realtime.p.replace(/,/g, '');
                }
                if (typeof this_data.current.rate_dollar_coin != "undefined") {
                    window.source_price['rate_dollar_coin'] = this_data.current.rate_dollar_coin.p.replace(/,/g, '');
                }
                if (typeof this_data.current.price_eur != "undefined") {
                    window.source_price['price_eur'] = this_data.current.price_eur.p.replace(/,/g, '');
                }
                if (typeof this_data.current.price_gbp != "undefined") {
                    window.source_price['price_gbp'] = this_data.current.price_gbp.p.replace(/,/g, '');
                }
                if (typeof this_data.current.price_aed != "undefined") {
                    window.source_price['price_aed'] = this_data.current.price_aed.p.replace(/,/g, '');
                }
                if (typeof this_data.current.mesghal != "undefined") {
                    window.source_price['mesghal'] = this_data.current.mesghal.p.replace(/,/g, '');
                }
                if (typeof this_data.current.geram18 != "undefined") {
                    window.source_price['geram18'] = this_data.current.geram18.p.replace(/,/g, '');
                }
                if (typeof this_data.current.geram24 != "undefined") {
                    window.source_price['geram24'] = this_data.current.geram24.p.replace(/,/g, '');
                }
                if (typeof this_data.current.sekee != "undefined") {
                    window.source_price['sekee'] = this_data.current.sekee.p.replace(/,/g, '');
                }
                if (typeof this_data.current.sekeb != "undefined") {
                    window.source_price['sekeb'] = this_data.current.sekeb.p.replace(/,/g, '');
                }
                if (typeof this_data.current.rob != "undefined") {
                    window.source_price['rob'] = this_data.current.rob.p.replace(/,/g, '');
                }
                if (typeof this_data.current.nim != "undefined") {
                    window.source_price['nim'] = this_data.current.nim.p.replace(/,/g, '');
                }
                if (typeof this_data.current.gerami != "undefined") {
                    window.source_price['gerami'] = this_data.current.gerami.p.replace(/,/g, '');
                }
                if (typeof this_data['current']['crypto-bitcoin-irr'] != "undefined") {
                    window.source_price['crypto-bitcoin-irr'] = this_data['current']['crypto-bitcoin-irr']['p'].replace(/,/g, '');
                }
                if (typeof this_data['current']['crypto-ethereum-irr'] != "undefined") {
                    window.source_price['crypto-ethereum-irr'] = this_data['current']['crypto-ethereum-irr']['p'].replace(/,/g, '');
                }
                if (typeof this_data['current']['crypto-dash-irr'] != "undefined") {
                    window.source_price['crypto-dash-irr'] = this_data['current']['crypto-dash-irr']['p'].replace(/,/g, '');
                }
                if (typeof this_data['current']['crypto-ripple-irr'] != "undefined") {
                    window.source_price['crypto-ripple-irr'] = this_data['current']['crypto-ripple-irr']['p'].replace(/,/g, '');
                }
                if (typeof this_data['current']['crypto-litecoin-irr'] != "undefined") {
                    window.source_price['crypto-litecoin-irr'] = this_data['current']['crypto-litecoin-irr']['p'].replace(/,/g, '');
                }
                if (typeof this_data['current']['crypto-stellar-irr'] != "undefined") {
                    window.source_price['crypto-stellar-irr'] = this_data['current']['crypto-stellar-irr']['p'].replace(/,/g, '');
                }
                if (typeof this_data['current']['crypto-tether-irr'] != "undefined") {
                    window.source_price['crypto-tether-irr'] = this_data['current']['crypto-tether-irr']['p'].replace(/,/g, '');
                }
                this.market_sync(this_data.current, this.market_items());
                // var table_header_summary_container = $('.table-header-summary-container');
                // if (table_header_summary_container.length) {
                //     table_header_summary_container.each(function() {
                //         if (!$(this).hasClass('not-allowed-item')) {
                //             var this_indicator_index = $(this).attr('data-index');
                //             var this_indicator_data = this_data.current[this_indicator_index];
                //             var hs_now_price = $(this).find('.table-header-summary-bottom-price > div:first-child');
                //             if (typeof this_indicator_data != "undefined" && hs_now_price.text() != this_indicator_data.p) {
                //                 hs_now_price.text(this_indicator_data.p);
                //                 var hs_now_date = $(this).find('.table-header-summary-bottom-date > div:last-child span');
                //                 hs_now_date.text(this_indicator_data.ts.split(" ")[1]);
                //                 var hs_dt = $(this).find('.table-header-summary-bottom-dt > div:first-child');
                //                 hs_dt.text(this_indicator_data.d + ' (' + this_indicator_data.dp + '%)');
                //                 hs_dt.removeClass('high low').addClass(this_indicator_data.dt);
                //                 var hs_dt1 = $(this).find('.table-header-summary-bottom-dt1 > div:first-child');
                //                 var dt1_arr = this.dt_dp(this.dump_comma(this_indicator_data.p), hs_dt1.attr('data-price'));
                //                 hs_dt1.text(dt1_arr.d + ' (' + dt1_arr.dp + '%)');
                //                 hs_dt1.removeClass('high low').addClass(dt1_arr.dt);
                //                 var hs_dt2 = $(this).find('.table-header-summary-bottom-dt2 > div:first-child');
                //                 var dt2_arr = this.dt_dp(this.dump_comma(this_indicator_data.p), hs_dt2.attr('data-price'));
                //                 hs_dt2.text(dt2_arr.d + ' (' + dt2_arr.dp + '%)');
                //                 hs_dt2.removeClass('high low').addClass(dt2_arr.dt);
                //                 var hs_dt3 = $(this).find('.table-header-summary-bottom-dt3 > div:first-child');
                //                 var dt3_arr = this.dt_dp(this.dump_comma(this_indicator_data.p), hs_dt3.attr('data-price'));
                //                 hs_dt3.text(dt3_arr.d + ' (' + dt3_arr.dp + '%)');
                //                 hs_dt3.removeClass('high low').addClass(dt3_arr.dt)
                //             }
                //         }
                //     });
                // }
                // if ($('.worldmap').length) {
                //     var countries_arr = [];
                //     var map_tooltip_irator = 0;
                //     for (var key in this_data.current) {
                //         var this_matches = key.match(/diff_(.*?)_(.*)/);
                //         if (this_matches) {
                //             var this_code = this_matches[1].toUpperCase().substr(0, 2);
                //             var this_title = this_matches[1].toUpperCase() + '/' + this_matches[2].toUpperCase();
                //             var this_p = this_data.current[key].p;
                //             var this_country_elm = $("path[data-code='" + this_code + "']");
                //             countries_arr[map_tooltip_irator] = {
                //                 'code': this_code,
                //                 'title': this_title,
                //                 'price': this_p
                //             };
                //             map_tooltip_irator++
                //         }
                //     }
                //     if (countries_arr.length) {
                //         window.world_map_countries_arr = countries_arr
                //     }
                // }
                // if (window.location.hostname != 'www.shakhesban.com' && $(window).width() > 760 && $('#tolerance_low').length && $('#last').length) {
                //     var is_fa = !0;
                //     if ($('html').attr('lang') != 'fa' || document.location.pathname === '/en')
                //         is_fa = !1;
                //     var updated = 0;
                //     var tolerance_types = ['tolerance_high', 'tolerance_low'];
                //     var html = '';
                //     var row;
                //     var row_p;
                //     var row_dt;
                //     var row_change;
                //     var row_name_or_slug;
                //     var not_allowed;
                //     var not_allowed_class;
                //     for (var tolerance_i in ['tolerance_high', 'tolerance_low']) {
                //         var tolerance_type = tolerance_types[tolerance_i];
                //         if (this_data[tolerance_type]) {
                //             for (var i in this_data[tolerance_type]) {
                //                 row = this_data[tolerance_type][i];
                //                 row_p = row.p;
                //                 row_dt = row.dt;
                //                 row_change = '(' + row.dp + '%) ' + row.d;
                //                 row_name_or_slug = row.name ? row.name : row.slug;
                //                 not_allowed = false;
                //                 not_allowed_class = '';
                //                 if (window.hide_items.indexOf(row_name_or_slug) != -1) {
                //                     not_allowed = true;
                //                     not_allowed_class = 'not-allowed-item';
                //                     row_dt = '';
                //                     row_p = row_change = '<span class="not-allowed-dash"></span>';
                //                 }
                //                 var addClass = '';
                //                 if ($('#' + tolerance_type + ' tr[data-item-name-' + tolerance_type + '="' + row.item_id + '"][data-item-value="' + row.p + '"]').length == 0) {
                //                     addClass = 'row-change';
                //                     updated++
                //                 }
                //                 html += '<tr class="pointer ' + addClass + ' ' + not_allowed_class + '" data-item-name-' + tolerance_type + '="' + row.item_id + '" data-item-value="' + row.p + '" onclick="window.location=\'profile/' + row_name_or_slug + '\'">' + '<th>' + (is_fa ? row.title : row.title_en) + '</th>' + '<td>' + row_p + '</td>' + '<td><span class="' + row_dt + '">' + row_change + '</span></td>' + '<td class="chart-td"><a data-tooltip="نمودار" class="chart-icon" target="_blank" href="profile/' + row_name_or_slug + '"></a></td>' + '</tr>'
                //             }
                //             $('#' + tolerance_type + ' tbody').html(html)
                //         }
                //     }
                //     if (this_data.last) {
                //         for (i in this_data.last) {
                //             row = this_data.last[i];
                //             row_p = row.p;
                //             row_dt = row.dt;
                //             row_change = '(' + row.dp + '%) ' + row.d;
                //             row_name_or_slug = row.name ? row.name : row.slug;
                //             not_allowed = false;
                //             not_allowed_class = '';
                //             if (window.hide_items.indexOf(row_name_or_slug) != -1) {
                //                 not_allowed = true;
                //                 not_allowed_class = 'not-allowed-item';
                //                 row_dt = '';
                //                 row_p = row_change = '<span class="not-allowed-dash"></span>';
                //             }
                //             addClass = '';
                //             if ($('#last tr[data-item-name-last="' + row.item_id + '"][data-item-value="' + row.p + '"]').length == 0) {
                //                 addClass = 'row-change';
                //                 updated++
                //             }
                //             html += '<tr class="pointer ' + addClass + ' ' + not_allowed_class + '" data-item-name-last="' + row.item_id + '" data-item-value="' + row.p + '" onclick="window.location=\'profile/' + row_name_or_slug + '\'">' + '<th>' + (is_fa ? row.title : row.title_en) + '</th>' + '<td>' + row_p + '</td>' + '<td><span class="' + row_dt + '">' + row_change + '</span></td>' + '<td class="chart-td"><a data-tooltip="نمودار" class="chart-icon" target="_blank" href="profile/' + row_name_or_slug + '"></a></td>' + '</tr>'
                //         }
                //         $('#last tbody').html(html)
                //     }
                //     if (updated) {
                //         setTimeout(function() {
                //             $('tr[data-item-name-tolerance_low].row-change').removeClass('row-change');
                //             $('tr[data-item-name-tolerance_high].row-change').removeClass('row-change');
                //             $('tr[data-item-name-last].row-change').removeClass('row-change')
                //         }, 3000)
                //     }
                // }
            }
        },
        market_sync(data, market_items) {
            if (Object.keys(data).length) {
                var updated = 0;
                var val;
                var item;
                for (var key in market_items.l) {
                    val = market_items.l[key];
                    if (data[key] && data[key].p !== val) {
                        item = data[key];
                        $("#l-" + key).attr('class', item.dt);
                        $("#l-" + key + " .info-price").text(item.p);
                        $("#l-" + key + " .info-change").text(item.d + " (" + item.dp + "%)")
                    }
                }
                for (key in market_items.f) {
                    val = market_items.f[key];
                    if (data[key] && data[key].p !== val) {
                        item = data[key];
                        this.market_row($("#f-" + key), key, item);
                        updated++
                    }
                }
                for (key in market_items.p) {
                    val = market_items.p[key];
                    if (data[key] && data[key].p !== val) {
                        $('[data-market-p="' + key + '"]').text(data[key].p);
                        if (data[key].p.replace(/,/g, '') > val.replace(/,/g, '')) {
                            $('[data-market-p="' + key + '"]').addClass('p-high');
                        } else {
                            $('[data-market-p="' + key + '"]').addClass('p-low');
                        }
                        setTimeout(function() {
                            $("[data-market-p]").removeClass('p-high p-low');
                        }, 17000);
                        updated++
                    }
                }
                for (key in market_items.row) {
                    val = market_items.row[key];
                    if (data[key] && data[key].p !== val) {
                        item = data[key];
                        // TODO: var length = $('[data-market-row="' + key + '"]').size();
                        // this.market_row($('[data-market-row="' + key + '"]'), key, item);
                        // if (length > 1)
                        //     this.market_row($('[data-market-row="' + key + '"]').eq(1), key, item);
                        // if (length > 2)
                        //     this.market_row($('[data-market-row="' + key + '"]').eq(2), key, item);
                        updated++
                    }
                }
                for (key in market_items.id) {
                    val = market_items.id[key];
                    if (data[key] && data[key].p !== val) {
                        item = data[key];
                        this.market_row($('[data-market-row="' + key + '"]'), key, item);
                        // TODO: if ($('[data-market-row="' + key + '"]').size() > 1)
                        //     this.market_row($('[data-market-row="' + key + '"]').eq(1), key, item);
                        updated++
                    }
                }
                // if (updated) {}
            }
        },
        market_items() {
            var values = {
                l: {},
                f: {},
                row: {},
                id: {},
                p: {}
            };
            $('[data-market-row]').each(function() {
                var key = $(this).attr('data-market-row');
                var key_name = isNaN(key) ? 'row' : 'id';
                var target_cell = 0;
                if (key == 'ati1' || key == 'ati2' || key == 'ati3' || key == 'ati4' || key == 'ati5')
                    target_cell = 1;
                if (!$(this).hasClass('not-allowed-item')) {
                    if (this.hasAttribute('data-market-coding')) {
                        values[key_name][key] = $(this).find('[data-market-name="p"]').text();
                    } else {
                        values[key_name][key] = $(this).find('td').eq(target_cell).text();
                    }
                }
            });
            $('[data-market-p]').each(function() {
                var key = $(this).attr('data-market-p');
                values.p[key] = $(this).text();
            });
            $('[id^="f-"]').each(function() {
                var key = $(this).attr('id').replace('f-', '');
                var target_cell = 0;
                if (key == 'ati1' || key == 'ati2' || key == 'ati3' || key == 'ati4' || key == 'ati5')
                    target_cell = 1;
                if (!$(this).hasClass('not-allowed-item')) {
                    values.f[key] = $(this).find('td').eq(target_cell).text()
                }
            });
            $('[id^="l-"]').each(function() {
                var key = $(this).attr('id').replace('l-', '');
                if (!$(this).hasClass('not-allowed-item')) {
                    values.l[key] = $(this).find('.info-price').text()
                }
            });
            return values
        },
        dt_dp($price, $basis_price) {
            var $d = $price - $basis_price;
            var $dp = this.number_format((Math.abs($d) * 100) / $basis_price, 2);
            var $dt;
            if ($d > 0)
                $dt = 'high';
            else if ($d < 0)
                $dt = 'low';
            else
                $dt = '';
            return {
                'd': this.number_format(Math.abs($d)),
                'dp': $dp,
                'dt': $dt
            }
        },
        dump_comma(str) {
            return Number(str.replace(/,/g, ''))
        },
        market_row(object, key, item) {
            var addEvent = void 0;
            var removeEvent = void 0;
            if (window.addEventListener) {
                addEvent = function(obj, type, fn) {
                    obj.addEventListener(type, fn, !1)
                }
                ;
                removeEvent = function(obj, type, fn) {
                    obj.removeEventListener(type, fn, !1)
                }
            } else if (document.attachEvent) {
                addEvent = function(obj, type, fn) {
                    var eProp;
                    eProp = type + fn;
                    obj["e" + eProp] = fn;
                    obj[eProp] = function() {
                        obj["e" + eProp](window.event)
                    }
                    ;
                    obj.attachEvent("on" + type, obj[eProp])
                }
                ;
                removeEvent = function(obj, type, fn) {
                    var eProp;
                    eProp = type + fn;
                    obj.detachEvent("on" + type, obj[eProp]);
                    obj[eProp] = null;
                    obj["e" + eProp] = null
                }
            }

            var tooltip = {
                offset: 10,
                show: function(e, element, text) {
                    var content;
                    text = element.getAttribute('data-title') || element.getAttribute('title');
                    content = document.createElement("div");
                    content.setAttribute("id", "tooltip");
                    document.body.appendChild(content);
                    tooltip.move(e, element);
                    content.style.opacity = "1.0";
                    content.style.filter = "alpha(opacity=100)";
                    content.style.msfilter = '"progid:DXImageTransform.Microsoft.Alpha(Opacity=100)"';
                    content.innerHTML = text;
                    if (element.getAttribute('title')) {
                        element.setAttribute('data-title', element.getAttribute('title'));
                        element.removeAttribute('title')
                    }
                },
                move: function(e, element) {
                    var add_y, content, t_height, x, y;
                    content =  document.getElementById('tooltip');
                    y = e.clientY + window.pageYOffset;
                    x = (e.clientX + window.pageXOffset) - 0;
                    if (!element.hasAttribute('data-tooltip-bottom')) {
                        y = y - 65
                    }
                    if (element.hasAttribute('data-tooltip-bottom')) {
                        x = x - 200
                    }
                    if (element.hasAttribute('data-tooltip-left')) {
                        x = x - 225
                    }
                    t_height = content.offsetHeight;
                    add_y = t_height < 50 ? 0 : t_height - (t_height / 2.5);
                    add_y = t_height > 150 ? t_height - (t_height / 3.3) : add_y;
                    add_y = t_height > 200 ? t_height - (t_height / 4.0) : add_y;
                    content.style.top = (y - add_y) + "px";
                    return content.style.left = (x - (content.offsetWidth / 2)) + "px"
                },
                hide: function() {
                    var el, k, len, ref;
                    ref = document.getElementsByTagName('div');
                    for (k = 0,
                    len = ref.length; k < len; k++) {
                        el = ref[k];
                        if (el && el.getAttribute('id') === 'tooltip') {
                            el.remove();
                            el.className = "hide"
                        }
                    }
                },
                invoke: function(element) {
                    addEvent(element, 'mouseover', (function(event) {
                        return tooltip.show(event, this)
                    }
                    ));
                    addEvent(element, 'mousemove', (function(event) {
                        return tooltip.move(event, this)
                    }
                    ));
                    return addEvent(element, 'mouseout', (function() {
                        return tooltip.hide()
                    }
                    ))
                }
            };

            var row = object[0];
            var cells = row.getElementsByTagName('td');
            var is_main = cells.length > 3;
            var target_cell = 0;
            if (key == 'ati1' || key == 'ati2' || key == 'ati3' || key == 'ati4' || key == 'ati5')
                target_cell = 1;
            var has_a = row.getAttribute('data-title');
            var last = has_a ? "<hr>" + has_a : "";
            var ref5;
            var match = (ref5 = last.match("<div class='highlight'>(.*)</div>")) != null ? ref5[0] : void 0;
            last = last.split("<hr>").splice(0, 7).join('<hr>');
            if (match) {
                last = last.replace(match, '')
            }
            last = last + (match ? match : '');
            var is_fa = !0;
            if ($('html').attr('lang') != 'fa' || document.location.pathname === '/en')
                is_fa = !1;
            var this_time = is_fa ? item.t : item.t_en;
            var change = "<span class='type " + item.dt + "'>(" + item.dp + "%) " + item.d + "</span>";
            var _in = 'در';
            row.setAttribute('data-title', "<div class='tooltip-row'>" + "<span class='tooltip-row-txt'>" + item.p + " " + _in + " " + this_time + "</span>" + "<span class='tooltip-row-change'>" + change + "</span>" + "</div><hr>" + last);
            if (!has_a) {
                tooltip.invoke(row)
            }
            if (row.hasAttribute('data-market-coding')) {
                $(row).find('[data-market-name="p"]').text(item.p);
                $(row).find('[data-market-name="dv"]').text(item.d);
                $(row).find('[data-market-name="dp"]').text(item.dp + '%');
                $(row).find('[data-market-name="d"]').text(item.d + " (" + item.dp + "%)");
                $(row).find('[data-market-name="l"]').text(item.l);
                $(row).find('[data-market-name="h"]').text(item.h);
                $(row).find('[data-market-name="t"]').text(item.t);
                if ($(row).find('[data-market-name="datetime"]').length) {
                    var ex = item.ts.split(' ');
                    var ex1 = ex[0].split('-');
                    var j = this.gregorian_to_jalali(parseInt(ex1[0]), parseInt(ex1[1]), parseInt(ex1[2])).join('/') + ' ' + ex[1];
                    $(row).find('[data-market-name="datetime"]').text(j);
                }
                $(row).find('[data-market-name="dv"]').removeClass('high low').addClass(item.dt);
                $(row).find('[data-market-name="dp"]').removeClass('high low').addClass(item.dt);
                $(row).find('[data-market-name="d"]').removeClass('high low').addClass(item.dt);
                var badge_tag = item.dt == 'high' ? 'badge-tag-grren' : 'badge-tag-red';
                $(row).find('[data-market-name="p"]').removeClass('badge-tag-grren badge-tag-red').addClass(badge_tag);
            } else {
                if (is_main) {
                    if (row.hasAttribute('data-change')) {
                        cells[0].innerHTML = item.p;
                        cells[1].setAttribute('class', item.dt);
                        cells[2].setAttribute('class', item.dt);
                        cells[1].innerHTML = "" + item.d;
                        cells[2].innerHTML = item.dp + "%";
                        cells[3].innerHTML = item.l;
                        cells[4].innerHTML = item.h;
                        cells[5].innerHTML = this_time
                    } else {
                        cells[0].innerHTML = item.p;
                        cells[1].setAttribute('class', item.dt);
                        cells[1].innerHTML = "(" + item.dp + "%) " + item.d;
                        cells[2].innerHTML = item.l;
                        cells[3].innerHTML = item.h;
                        if (key === 'ati1' || key === 'ati2' || key === 'ati3' || key === 'ati4' || key === 'ati5') {
                            cells[0].innerHTML = item.settlement;
                            cells[1].innerHTML = item.p;
                            cells[1].removeAttribute('class');
                            cells[2].setAttribute('class', item.dt);
                            cells[2].innerHTML = "(" + item.dp + "%) " + item.d;
                            cells[3].innerHTML = item.l;
                            cells[4].innerHTML = item.h;
                            cells[5].innerHTML = this_time
                        } else {
                            cells[4].innerHTML = this_time
                        }
                    }
                } else {
                    cells[0].innerHTML = item.p;
                    cells[1].setAttribute('class', item.dt);
                    cells[1].innerHTML = "(" + item.dp + "%) " + item.d
                }
            }
            $(row).removeClass('tr- tr-high tr-low');
            row.setAttribute('class', 'row-' + item.dt + ' tr-' + item.dt);
            var highlighted_rows = {};
            var tol_td;
            var tol_dt_arr;
            highlighted_rows[key] = {
                element: row,
                time: new Date()
            };
            if ($(row).find('.dt0').length) {
                tol_td = $(row).find('td.dt0');
                if (tol_td.attr('data-price') && tol_td.attr('data-price') != '0') {
                    tol_dt_arr = this.dt_dp(this.dump_comma(item.p), tol_td.attr('data-price'));
                    tol_td.text('(' + tol_dt_arr.dp + '%) ' + tol_dt_arr.d);
                    tol_td.removeClass('high low').addClass(tol_dt_arr.dt);
                }
            }
            if ($(row).find('.dt1').length) {
                tol_td = $(row).find('td.dt1');
                if (tol_td.attr('data-price') && tol_td.attr('data-price') != '0') {
                    tol_dt_arr = this.dt_dp(this.dump_comma(item.p), tol_td.attr('data-price'));
                    tol_td.text('(' + tol_dt_arr.dp + '%) ' + tol_dt_arr.d);
                    tol_td.removeClass('high low').addClass(tol_dt_arr.dt);
                }
            }
            if ($(row).find('.dt2').length) {
                tol_td = $(row).find('td.dt2');
                if (tol_td.attr('data-price') && tol_td.attr('data-price') != '0') {
                    tol_dt_arr = this.dt_dp(this.dump_comma(item.p), tol_td.attr('data-price'));
                    tol_td.text('(' + tol_dt_arr.dp + '%) ' + tol_dt_arr.d);
                    tol_td.removeClass('high low').addClass(tol_dt_arr.dt);
                }
            }
            if ($(row).find('.dt3').length) {
                tol_td = $(row).find('td.dt3');
                if (tol_td.attr('data-price') && tol_td.attr('data-price') != '0') {
                    tol_dt_arr = this.dt_dp(this.dump_comma(item.p), tol_td.attr('data-price'));
                    tol_td.text('(' + tol_dt_arr.dp + '%) ' + tol_dt_arr.d);
                    tol_td.removeClass('high low').addClass(tol_dt_arr.dt);
                }
            }
        },
        number_format(number, decimals, decPoint, thousandsSep) {
            number = (number + '').replace(/[^0-9+\-Ee.]/g, '')
            var n = !isFinite(+number) ? 0 : +number
            var prec = !isFinite(+decimals) ? 0 : Math.abs(decimals)
            var sep = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep
            var dec = (typeof decPoint === 'undefined') ? '.' : decPoint
            var s = ''
            var toFixedFix = function(n, prec) {
                var k = Math.pow(10, prec)
                return '' + (Math.round(n * k) / k).toFixed(prec)
            }
            s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.')
            if (s[0].length > 3) {
                s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep)
            }
            if ((s[1] || '').length < prec) {
                s[1] = s[1] || ''
                s[1] += new Array(prec - s[1].length + 1).join('0')
            }
            return s.join(dec)
        },
        gregorian_to_jalali(gy, gm, gd) {
            var g_d_m = [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334];
            var jy = (gy <= 1600) ? 0 : 979;
            gy -= (gy <= 1600) ? 621 : 1600;
            var gy2 = (gm > 2) ? (gy + 1) : gy;
            var days = (365 * gy) + (parseInt((gy2 + 3) / 4)) - (parseInt((gy2 + 99) / 100)) + (parseInt((gy2 + 399) / 400)) - 80 + gd + g_d_m[gm - 1];
            jy += 33 * (parseInt(days / 12053));
            days %= 12053;
            jy += 4 * (parseInt(days / 1461));
            days %= 1461;
            jy += parseInt((days - 1) / 365);
            if (days > 365)
                days = (days - 1) % 365;
            var jm = (days < 186) ? 1 + parseInt(days / 31) : 7 + parseInt((days - 186) / 30);
            var jd = 1 + ((days < 186) ? (days % 31) : ((days - 186) % 30));
            return [jy, jm, jd];
        },
    },

}
</script>