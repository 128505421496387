const static_crypto_filter_market = {
    empty: {
        value: 'نمایش همه',
    },
    education: {
        value: 'آموزشی',
    },
    social: {
        value: 'اجتماعی',
    },
    currency: {
        value: 'ارز های دیجیتال',
    },
    mining: {
        value: 'استخراج',
    },
    stablecoin: {
        value: 'استیبل کوین‌ها',
    },
    'smart-contract': {
        value: 'اسمارت کنتراکت ها',
    },
    'real-estate': {
        value: 'املاک و مستغلات',
    },
    security: {
        value: 'امنیت',
    },
    energy: {
        value: 'انرژی',
    },
    iot: {
        value: 'اینترنت اشیا',
    },
    marketplace: {
        value: 'بازارچه ها',
    },
    market: {
        value: 'بازارها',
    },
    marketing: {
        value: 'بازاریابی',
    },
    gaming: {
        value: 'بازی و گیم',
    },
    banking: {
        value: 'بانکداری',
    },
    dapp: {
        value: 'برنامه های غیرمتمرکز',
    },
    ticketing: {
        value: 'بلیط فروشی',
    },
    insurance: {
        value: 'بیمه',
    },
    'cloud-computing': {
        value: 'پردازش ابری',
    },
    protocol: {
        value: 'پروتکل',
    },
    predictions: {
        value: 'پیش بینی',
    },
    crowdfunding: {
        value: 'تأمین سرمایه گروهی',
    },
    advertising: {
        value: 'تبلیغات',
    },
    'e-commerce': {
        value: 'تجارت الکترونیک',
    },
    'hi-tech': {
        value: 'تکنولوژی های نوین',
    },
    'exchange-token': {
        value: 'توکن صرافی‌ها',
    },
    'leveraged-token': {
        value: 'توکن های اهرمی',
    },
    'fan-token': {
        value: 'توکن های هواداری',
    },
    'financial-services': {
        value: 'خدمات مالی',
    },
    'real-assets': {
        value: 'دارایی‌های حقیقی',
    },
    defi: {
        value: 'دیفای',
    },
    'cloud-storage': {
        value: 'ذخیره سازی ابری',
    },
    media: {
        value: 'رسانه ها',
    },
    'blockchain-infrastructure': {
        value: 'زیرساخت‌های بلاکچین',
    },
    entertainment: {
        value: 'سرگرمی',
    },
    'blockchain-service': {
        value: 'سرویس‌های بلاکچین',
    },
    'data-service': {
        value: 'سرویس‌های داده',
    },
    healthcare: {
        value: 'سلامت',
    },
    'tokenized-stock': {
        value: 'سهام',
    },
    network: {
        value: 'شبکه',
    },
    'social-network': {
        value: 'شبکه های اجتماعی',
    },
    payments: {
        value: 'شبکه های پرداخت',
    },
    'crypto-fund': {
        value: 'صندوق های سرمایه گذاری رمزارز',
    },
    card: {
        value: 'کارت',
    },
    business: {
        value: 'کسب و کار',
    },
    'real-business': {
        value: 'کسب و کارهای حقیقی',
    },
    wallet: {
        value: 'کیف پول',
    },
    finance: {
        value: 'مالی',
    },
    masternode: {
        value: 'مستر ناد',
    },
    collaboration: {
        value: 'مشارکت',
    },
    'trading-and-investment': {
        value: 'معامله گری و سرمایه گذاری',
    },
    mobile: {
        value: 'موبایل',
    },
    meme: {
        value: 'میم کوین‌ها',
    },
    'artificial-intelligence': {
        value: 'هوش مصنوعی',
    },
    verification: {
        value: 'هویت سنجی',
    },
    vr: {
        value: 'واقعیت مجازی',
    },
    'non-fungible-tokens-nft': {
        value: 'Non-Fungible Tokens (NFT)',
    },
}

export default static_crypto_filter_market;